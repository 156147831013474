import { lazy } from "react";
import { Navigate } from "react-router-dom";
//import {History} from '../History'
//import { createBrowserHistory } from "history";
const baseUrl = "/";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
const BlankLayout = lazy(() => import("../layouts/BlankLayout/BlankLayout.js"));
/****End Layouts*****/

/*****Pages******/
const Home = lazy(() => import("../views/Home"));
const Error = lazy(() => import("../views/error/error"));
// const Login = lazy(() => import("../views/Login"));
// const MyNft = lazy(() => import("../views/MyNft"));
// const MintNft = lazy(() => import("../views/MintNft"));
// const TokenStake = lazy(() => import("../views/TokenStake"));
const NFTStake = lazy(() => import("../views/NftStake"));
// const Governance = lazy(() => import("../views/Governance"));
// const Portfolio = lazy(() => import("../views/Protfolio"));
const Docs = lazy(() => import("../views/Docs"));
const Buyback = lazy(() => import("../views/Buyback"));
const Bribe = lazy(() => import("../views/Bribe"));
/*****Routes******/
const ThemeRoutes = [
  {
    path: `${baseUrl}`,
    element: <FullLayout />,
    children: [
      { path: baseUrl, element: <Navigate to={`${baseUrl}home`} /> },
      // { path: "myNft", element: <MyNft /> },
      // { path: "mint", element: <MintNft /> },
      // { path: "tokenStake", element: <TokenStake /> },
      { path: "stake", element: <NFTStake /> },
      { path: "bribe", element: <Bribe /> },
      // { path: "governance", element: <Governance /> },
      // { path: "portfolio", element: <Portfolio /> },
      { path: "docs", element: <Docs /> },
      { path: "board", element: <Buyback /> },
      { path: "*", element: <Navigate to={`${baseUrl}404`} /> },
    ],
  },
  {
    path: baseUrl,
    element: <BlankLayout />,
    children: [
      { path: "home", element: <Home /> },
      // { path: baseUrl, element: <Login /> },
      { path: "404", element: <Error /> },
      // { path: "login", element: <Login /> },
    ],
  },
];

export default ThemeRoutes;
