import React from "react";
import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import { Provider } from "react-redux";
import store from "./redux/Store";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./assets/jss/Theme-variable";
import { Web3ReactProvider } from "@web3-react/core";
import GlobalStyles from "./assets/jss/Globalstyles";
import { ethers } from "ethers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function getLibrary(provider, connector) {
  return new ethers.providers.Web3Provider(provider); // this will vary according to whether you use e.g. ethers or web3.js
}

const App = () => {
  const routing = useRoutes(Themeroutes);
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ToastContainer theme="dark" />
          {routing}
        </ThemeProvider>
      </Provider>
    </Web3ReactProvider>
  );
};

export default App;
